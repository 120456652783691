import isObject from "../utils/isObject";
import { BaseModel, Field } from "../definitions";
import _ from "lodash";

interface WrapValueFunction {
  (currentValue: BaseModel, newValue: BaseModel, fields: Array<Field>, duplicating?: boolean): BaseModel;
}

export function useFormValueWrapper(): WrapValueFunction {
  const wrapValue: WrapValueFunction = function (currentValue, newValue, fields, duplicating = false) {
    let returnValue = manageQueryFields(newValue, fields);
    if (!duplicating) returnValue = filterUnchangedFields(currentValue, returnValue, fields);
    return manageFileFields(currentValue, returnValue, fields);
  };

  function getItemValue(item: BaseModel, field: Field): string {
    let value = field.value ?? "";

    if (typeof value == "function") value = value(item);

    return value;
  }

  /**
   * Extract id from nested models
   *
   * @param {*} item
   * @returns
   */
  function extractIds(item: BaseModel, fields: Array<Field>) {
    const queryFields = fields.filter((field) => field.type == "query");
    const skipFields = fields.filter((field) => field.type == "file").map(({ name }) => name);
    const morphFields = queryFields.filter((field) => field.morph).map(({ name }) => name);

    return Object.keys(item).reduce((obj: any, key: string) => {
      if (skipFields.includes(key)) {
        obj[key] = item[key];
        return obj;
      }

      const field = fields.find((f) => f.name === key);
      const value = field && getItemValue(item[key], field);

      if (isObject(item[key]) && Object.keys(item[key]).includes("id")) {
        const modelValue = value ? _.get(item[key], value) : item[key];

        if (morphFields.includes(key))
          obj[key] = { connect: { id: modelValue.id, type: modelValue.__typename.toUpperCase() } };
        else obj[key] = { connect: modelValue.id };

        return obj;
      }

      if (Array.isArray(item[key])) {
        const belongsKey = field?.sync ? "sync" : "connect";

        obj[key] = {
          [belongsKey]: item[key].map((singleItem: BaseModel) =>
            value ? _.get(singleItem, value)?.id : singleItem.id
          ),
        };
        return obj;
      }

      obj[key] = item[key];
      return obj;
    }, {});
  }

  function arrayDifference(a: Array<string>, b: Array<string>): Array<string> {
    return a.filter((x) => !b.includes(x));
  }

  function manageQueryFields(model: BaseModel, fields: Array<Field>) {
    const newValue = extractIds(model, fields);

    fields
      .filter((field) => field.type == "query")
      .filter((field) => !field.bind?.multiple && newValue[field.name] === null)
      .forEach((field) => {
        delete newValue[field.name];
        newValue[field.name] = { disconnect: true };
      });

    return newValue;
  }

  function filterUnchangedFields(currentValue: BaseModel, newValue: BaseModel, fields: Array<Field>) {
    const originalItem = manageQueryFields(currentValue, fields);

    const query_fields = fields.filter((field) => field.type == "query");

    const belongs_to_keys = query_fields
      .filter((field) => !field.bind?.multiple && !field.morph)
      .map(({ name }) => name);
    const belongs_to_keys_morph = query_fields
      .filter((field) => !field.bind?.multiple && field.morph)
      .map(({ name }) => name);

    const belongs_to_many_fields = query_fields.filter((field) => field.bind?.multiple);
    const belongs_to_many_keys = belongs_to_many_fields.filter((field) => !field.sync).map(({ name }) => name);

    const file_keys = fields.filter((field) => field.type == "file").map(({ name }) => name);

    Object.keys(newValue).forEach((key) => {
      if (belongs_to_keys.includes(key)) {
        if (newValue[key].connect === originalItem[key]?.connect) delete newValue[key];
      } else if (belongs_to_keys_morph.includes(key)) {
        if (
          newValue[key].connect?.id === originalItem[key]?.connect?.id &&
          newValue[key].connect?.type === originalItem[key]?.connect?.type
        )
          delete newValue[key];
      } else if (file_keys.includes(key)) {
        return;
      } else if (belongs_to_many_keys.includes(key)) {
        const newValues = newValue[key]?.connect ?? [];

        const oldValues = currentValue[key]?.map(({ id }: { id: string }) => id) ?? [];

        const connect = arrayDifference(newValues, oldValues);
        const disconnect = arrayDifference(oldValues, newValues);
        newValue[key] = { connect, disconnect };
      } else if (newValue[key] === originalItem[key]) delete newValue[key];
    });

    return newValue;
  }

  function manageFileFields(currentValue: BaseModel, newValue: BaseModel, fields: Field[]) {
    const fileFields = fields.filter((field) => field.type == "file").map((field) => field.name);
    if (fileFields.length == 0) return newValue;

    newValue.files = { create: [], delete: [] };

    fileFields.forEach((field) => {
      if (newValue[field] && (newValue[field].id ?? -1) != currentValue[field]?.id) {
        newValue.files.create.push({
          name: field,
          file: newValue[field],
        });
      }

      if (currentValue[field] && currentValue[field].id != newValue[field]?.id) {
        newValue.files.delete.push(currentValue[field].id);
      }
      delete newValue[field];
    });
    return newValue;
  }

  return wrapValue;
}
